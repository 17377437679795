const language = {
    ru: {
        NavigationBar: {
            placeButton: "Места",
            historyButton: "Легенды",
            changeLanguage: "Change language",
            closeButton: "Закрыть"
        },
        isLoading: "Загрузка",
        DownloadBlock: "Доступно для скачивания"
    },
    en: {
        NavigationBar: {
            placeButton: "Places",
            historyButton: "Legends",
            changeLanguage: "Change language",
            closeButton: "Close"
        },
        isLoading: "Loading",
        DownloadBlock: "Available for download"
    },
    es: {
        NavigationBar: {
            placeButton: "Lugares",
            historyButton: "Leyendas",
            changeLanguage: "Change language",
            closeButton: "Cerrar"

        },
        isLoading: "Cargando",
        DownloadBlock: "Disponible para descarga"
    }
}

export default language
