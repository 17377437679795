import React, {useContext, useEffect, useState, useMemo} from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import { headers_token } from "../config";
import pass from "../assets/pass.png";
import MainContext from "../Context"
import Language from "../Language/Language";
const deg2rad = (number) => {
  return (number * Math.PI) / 180;
};
function kmLng () {
  const lng = localStorage.getItem("language")
  let km;
  if ( lng == "ru" ) {
    km = "км"
  }
  else if ( lng == "en" ) {
    km = "km"
  }
  else if ( lng == "es" ) {
    km = "km"
  }
  else {
    km = "km"
  }
  return km
}
function mLng () {
  const lng = localStorage.getItem("language")
  let m;
  if ( lng == "ru" ) {
    m = "метров"
  }
  else if ( lng == "en" ) {
    matchMedia = "meters"
  }
  else if ( lng == "es" ) {
    m = "metros"
  }
  else {
    m = "meters"
  }
  return m
}
const getDistance = (lat1, lon1, lat2, lon2) => {
  
  let radius = 6371;
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = parseFloat(radius) * c;
  let res;
  if (d < 1) {
  	res = `${parseInt(d * 1000)} ${mLng()}`
  } else {
  	res = `${parseInt(d)} ${kmLng()}`
  }
  return res;
};

export default function PlaceItemView({ item, expandHendler }) {
  const mainContext = useContext(MainContext);
  const tagsList = JSON.parse(localStorage.getItem("tags"))
  let history = useHistory();
  const [tag, setTag] = useState(null);
  const [location, setLocation] = useState(null);
  const [fixLocation, setFixLocation] = useState(false)
  const fetchTag = async () => {
    let findId;
    for(let i = 0; i < item.tags.length; i++){
      if (item.tags[i].id ==  1 || item.tags[i].id == 2 || item.tags[i].id ==  6 || item.tags[i].id ==  8 || item.tags[i].id ==  60 || item.tags[i].id == 61) {
        findId = item.tags[i].id
        break
      }
    }
    try {
      const response = await axios.get(
        `https://apimipt.4129.ru/api/place-tag?filter[id]=${findId}`,
        headers_token
      );
      const { paginator } = response.data.data;
        // console.log(paginator);
      setTag(paginator);
    } catch (e) {
      console.log(e);
    }
  };
  const Mounted = async () => {
    // console.log("mainContext.Tags",mainContext.Tags)
    let findId;
    for(let j = 0; j < item.tags.length; j++){
      Object.keys(tagsList).map((key, i) => {
        if ( item.tags[j].id == tagsList[key].id && tagsList[key].type == "use") {
          setTag(tagsList[key].name)
        }
      })
    }
    navigator.geolocation.getCurrentPosition(function (position) {
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
  }
  useMemo(() => {
    if (location != null) {
      setFixLocation(true)
    }
  }, [location])
  const moveToPlaceDetail = () => {

    if (!expandHendler) {
      history.push(`/p/${item.id}`)
    } else {
      if (expandHendler.expand) {
        history.push(`/p/${item.id}`)

      }

    }
    window.reload()
  }
  useEffect(() => {
    Mounted()
  }, []);
  function checkLanguage (type) {

    const lng = localStorage.getItem("language")
    let word;
    if (type == "CheckKm") {
      if ( lng == "ru" ) {
        word = "км"
      }
      else if ( lng == "en" ) {
        word = "km"
      }
      else if ( lng == "es" ) {
        word = "km"
      }
      else {
        word = "km"
      }
    }
    if (type == "planet") {
      if ( lng == "ru" ) {
        word = "Планета Земля"
      }
      else if ( lng == "en" ) {
        word = "Planet Earth"
      }
      else if ( lng == "es" ) {
        word = "Planeta Tierra"
      }
      else {
        word = "Planet Earth"
      }
    }
    return word
  }
  return (
    // <div onClick={() => moveToPlaceDetail()} style={styles.PlaceItem} >
    <div style={styles.PlaceItem} >
    <div style={styles.PlacesItem}>

      {item.cover.signatures.length > 0 && (
        <img
        style={styles.PlacesItemImage}
        src={`https://apimipt.4129.ru/get-file.php?id=${item.cover.signatures[0].id}&dir=${item.cover.signatures[0].dir}&path=${item.cover.signatures[0].path}&style=thumbnail`}
      />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
            {item.name}
          </span>
          <span style={{ color: "#0069B4", marginBottom: 2, fontSize: 14 }}>
            {!tag ? "-" : tag}
          </span>
          <span style={{ marginBottom: 2, fontSize: 14 }}>
            {!item.place ? "—" : item.place.address}
          </span>
        </div>
        <div style={{ display: "flex" ,flexDirection: "row", justifyContent: "space-between" }}>
        <span style={{backgroundColor: "white",paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 5,
              marginTop: 4, color: "#0069B4", fontSize: 12 }}>
                {!fixLocation
                  ? checkLanguage("planet")
                  : !item.place
                  ? checkLanguage("planet")
                  : item.drender }
              </span>
          {item.needPermit ? (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path d="M17.7083 17.5H2.29172C1.02753 17.5 0 16.4725 0 15.2083V4.79172C0 3.52753 1.02753 2.5 2.29172 2.5H17.7083C18.9725 2.5 20 3.52753 20 4.79172V15.2083C20 16.4725 18.9725 17.5 17.7083 17.5ZM2.29172 3.75C1.71753 3.75 1.25 4.21753 1.25 4.79172V15.2083C1.25 15.7825 1.71753 16.25 2.29172 16.25H17.7083C18.2825 16.25 18.75 15.7825 18.75 15.2083V4.79172C18.75 4.21753 18.2825 3.75 17.7083 3.75H2.29172Z" fill="#999999"/>
                  <path d="M6.25003 9.99999C5.10165 9.99999 4.16675 9.06493 4.16675 7.91671C4.16675 6.76833 5.10165 5.83327 6.25003 5.83327C7.39841 5.83327 8.33331 6.76833 8.33331 7.91671C8.33331 9.06493 7.39841 9.99999 6.25003 9.99999ZM6.25003 7.08327C5.79089 7.08327 5.41675 7.45742 5.41675 7.91671C5.41675 8.37585 5.79089 8.74999 6.25003 8.74999C6.70917 8.74999 7.08331 8.37585 7.08331 7.91671C7.08331 7.45742 6.70917 7.08327 6.25003 7.08327Z" fill="#999999"/>
                  <path d="M9.375 14.1667C9.03 14.1667 8.75 13.8867 8.75 13.5417V13.125C8.75 12.5508 8.28247 12.0833 7.70828 12.0833H4.79172C4.21753 12.0833 3.75 12.5508 3.75 13.125V13.5417C3.75 13.8867 3.47 14.1667 3.125 14.1667C2.78 14.1667 2.5 13.8867 2.5 13.5417V13.125C2.5 11.8608 3.52753 10.8333 4.79172 10.8333H7.70828C8.97247 10.8333 10 11.8608 10 13.125V13.5417C10 13.8867 9.72 14.1667 9.375 14.1667Z" fill="#999999"/>
                  <path d="M16.875 7.5H12.2917C11.9467 7.5 11.6667 7.22 11.6667 6.875C11.6667 6.53 11.9467 6.25 12.2917 6.25H16.875C17.22 6.25 17.5 6.53 17.5 6.875C17.5 7.22 17.22 7.5 16.875 7.5Z" fill="#999999"/>
                  <path d="M16.875 10.8333H12.2917C11.9467 10.8333 11.6667 10.5533 11.6667 10.2083C11.6667 9.86327 11.9467 9.58327 12.2917 9.58327H16.875C17.22 9.58327 17.5 9.86327 17.5 10.2083C17.5 10.5533 17.22 10.8333 16.875 10.8333Z" fill="#999999"/>
                  <path d="M16.875 14.1667H12.2917C11.9467 14.1667 11.6667 13.8867 11.6667 13.5417C11.6667 13.1967 11.9467 12.9167 12.2917 12.9167H16.875C17.22 12.9167 17.5 13.1967 17.5 13.5417C17.5 13.8867 17.22 14.1667 16.875 14.1667Z" fill="#999999"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

          ) : <></>}
        </div>
      </div>
    </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
    // paddingHorizontal: 20,
  },
  PlacesItem: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#F7F7F7",
    padding: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  PlacesItemImage: {
    width: 90,
    height: 90,
    marginRight: 10,
    borderRadius: 10,
  },
  PlacesItemDescription: {
    justifyContent: "space-between",
    flexDirection: "column",
    alignContent: "flex-start",
    alignItems: "flex-start",
    width: "70%",
  },
  moreLoading: {
    marginTop: 15,
    alignItems: "center",
  },
  PreviewScreenTitle: {
    fontWeight: "bold",
    fontSize: 48,
    marginBottom: 50,
  },
  PreviewScreenItemContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  PreviewScreenItemImageContainer: {
    paddingHorizontal: 10,
  },
  PreviewScreenItemTitle: {
    fontWeight: "bold",
  },
  PreviewScreenItemDescription: {
    width: 250,
  },
  PreviewScreenCustomButton: {
    marginTop: 100,
    backgroundColor: "#0069B4",
    paddingHorizontal: 70,
    paddingVertical: 12,
    borderRadius: 20,
  },
  PreviewScreenCustomButtonText: {
    color: "#FFFFFF",
  },
  containerPreview: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 40,
  },
};
