import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Navbar from "./components/Navbar";
import PlaceDetails from "./page/PlaceDetails";
import Place from "./page/Place";
import Legends from "./page/Legends";
import PlaceDetailView from "./page/PlaceDetail"
import { headers_token } from "./config";
import axios from "axios";
import MainContext from "./Context"
import Language from "./Language/Language"
import detectBrowserLanguage from 'detect-browser-language'
import QrCode from "./page/QrCode/index"
function App() {
    const [Tags, setTags] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    async function getAllTags () {
        let URL = "https://apimipt.4129.ru/api/place-tag?perpage=1000"
        try {
            const response = await axios.get(
                URL,
                headers_token
            );
            localStorage.setItem("tags", JSON.stringify(response.data.data.paginator))
            setIsLoading(true)
        }
        catch (e) {
            console.log(e)
        }
    }
    function checkLanguage (type) {

        const lng = localStorage.getItem("language")
        let word;
        if (type == "SheetCloseButton") {
            if ( lng == "ru" ) {
                word = Language.ru.NavigationBar.closeButton
            }
            else if ( lng == "en" ) {
                word = Language.en.NavigationBar.closeButton
            }
            else if ( lng == "es" ) {
                word = Language.es.NavigationBar.closeButton
            }
            else {
                word = Language.en.NavigationBar.closeButton
            }
        }
        return word
    }
    const setLanguage = () => {
        const language_store = localStorage.getItem("language")
        const lang = navigator.language || navigator.userLanguage;
        if ( !language_store ) {
            if ( lang == "en-us" ){
                localStorage.setItem("language", "en")
            }
            else if ( lang == "ru-ru" ) {
                localStorage.setItem("language", "ru")
            }
            else if ( lang == "es" ) {
                localStorage.setItem("language", "es")
            }
            else if (lang != "en-us" && lang != "ru-ru" && lang != "es") {
                localStorage.setItem("language", "en")
            }
        }
    }
    useEffect(() => {
        setLanguage()
        getAllTags()
        setIsLoading(true)

    }, [])


    const [menu, setMenu] = useState(false);
    const [BottomSheetIsOpen, setBottomSheetIsOpen] = useState(false)
    function CloseBottomSheet () {
        setBottomSheetIsOpen(false)
        setMenu(false)
        document.body.style.overflow = "auto"
    }

    function changeLanguage (language) {
        localStorage.setItem("language", language)
        setBottomSheetIsOpen(false)
        window.location.href = 'http://app.mipt.ru/'
    }
  return (
      isLoading && (
          <MainContext.Provider value={{Tags}}>
              <Router>
                  <Navbar sheet={{BottomSheetIsOpen, setBottomSheetIsOpen}} controll={{menu, setMenu}} />
                  {/* BOTTOM SHEET OVERLAY */}
                  <div style={{
                      backgroundColor: "#000000",
                      width: "100%",
                      height: "100%",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      zIndex: 9999999999,
                      opacity: 0.2,
                      display: BottomSheetIsOpen ? "block" : "none",
                      transition: "0.5s"
                  }}
                  />
      
                  {/* BOTTOM SHEET */}
                  <div style={{
      
                      display: BottomSheetIsOpen ? "block" : "none",
                      transition: "0.5s",
                      position: "fixed", bottom: 0, left: 0,width: "100%",zIndex: 99999999999}}>
                      {/**/}
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                          <div style={{backgroundColor: "#FFFFFF", border: "1px solid #CCCCCC",borderRadius: 8,display: "flex", flexDirection: "column",alignItems: "center", width: "90%"}}>
                              <Link onClick={() => {
                                  changeLanguage("ru")
                              }} style={BottomSheetItem}>Русский</Link>
                              <Link onClick={() => {
                                  changeLanguage("en")
                              }} style={BottomSheetItem}>English</Link>
                              <Link onClick={() => {
                                  changeLanguage("es")
                              }} style={{...BottomSheetItem, border: "none"}}>Español</Link>
                          </div>
                          <div style={{backgroundColor: "#FFFFFF", border: "1px solid #CCCCCC", marginTop: 10,marginBottom: 10, borderRadius: 8,display: "flex", flexDirection: "column",alignItems: "center", width: "90%"}}>
                              <Link onClick={() => {
                                  CloseBottomSheet()
                              }} style={{...BottomSheetItem, border: "none", fontWeight: 500, color: "tomato"}}>
                                  {checkLanguage("SheetCloseButton")}
                              </Link>
                          </div>
                      </div>
                  </div>
      
                  <div>
      
                      <Switch>
                          <Route exact path="/">
                              <Place />
                          </Route>
                          <Route path="/h/:id" component={Legends} />
                          <Route path="/history" component={Legends} />
                          {/*    <Legends />*/}
                          {/*</Route>*/}
                          <Route path="/details">
                              <PlaceDetails />
                          </Route>
                          <Route path="/p/:id" component={PlaceDetailView}/>
                          <Route path="/qr/:id" component={QrCode}/>
                      </Switch>
                  </div>
      
              </Router>
          </MainContext.Provider>
      )

  );
}
const BottomSheetItem = {
    paddingTop: 15,
    paddingBottom: 15,
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #CCCCCC",
    textDecoration: "none"
}
export default App;
