import React, { useEffect, useState, useMemo } from "react"
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import {headers_token} from "../../../config";
import PlaceItemView from "../../PlaceItemView"
import {useHistory, useLocation} from "react-router-dom";
export default function CardDetailView ( props ) {
    const { selected: {transitionUrl, setTransitionUrl}, transition: { selectedTransitionUrl, setSelectedTransitionUrl } } = props
    let history = useHistory();
    const [isLoadingPlaces, setIsLoadingPlaces] = useState(false)
    const [data, setData] = useState(null)
    const [animated, setAnimated] = useState(false)
    function createMarkup() {
        return {
            __html: transitionUrl.content
        }
    }
    async function Mounted () {
        let query = "https://apimipt.4129.ru/api/place?"
        for (let i = 0; i < transitionUrl.navigatePlaces.length; i++) {
            query += `filter[id][]=${transitionUrl.navigatePlaces[i].id}`
            if ( i != transitionUrl.navigatePlaces.length - 1 ) {
                query += "&"
            }
        }
        console.log(query)
        try {
            const response = await axios.get(
                query,
                headers_token
            );
            const { paginator } = response.data.data;
            setData(paginator)

        } catch (error) {
            console.log(error);
        }
    }
    useMemo(() => {
        if ( data != null ) {
            setIsLoadingPlaces(true)
            console.log(data)
        }
    },[data])
    useEffect(() => {

        if (transitionUrl.navigatePlaces.length > 0 ) {
            Mounted()
        }

    }, [])
    function lengthData (data) {
        let array = []
        for(let item in data) {
            array.push(item)
        }
        return array.length
    }
    return(

        <div style={{
            position: "absolute",
            top: animated ? 1000 : 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            zIndex: 99998,
            transition: "0.4s"
        }} >
            <img src={`https://apimipt.4129.ru/get-file.php?id=${transitionUrl.summaryImage.signatures[0].id}&dir=${transitionUrl.summaryImage.signatures[0].dir}&path=${transitionUrl.summaryImage.signatures[0].path}&style=thumbnail`}
                 style={{
                     width: "100%",
                     height: 390,
                     objectFit: "cover",
                 }}
            />
            <h5 style={{
                position: "absolute",
                top: 30,
                left: 10,
                color: "#FFFFFF",
                zIndex: 99999,
                width: "80%",
                transition: "0.4s"
            }}>{transitionUrl.title}</h5>
            <h2 style={{
                position: "absolute",
                top: 22,
                right: 20,
                zIndex: 9999999,
                transition: "ease-in-out 0.4s",
                color: "#FFFFFF"
            }}

                onClick={() => {
                    setAnimated(true)
                    setTimeout(() => {
                        setSelectedTransitionUrl(false)
                        setTransitionUrl(null)
                        history.push(`/history`)
                    }, 400)

                }}
            ><AiFillCloseCircle />
            </h2>
            <div style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#FFFFFF"
            }}>
                <div style={{ padding: 10,
                    transition: "ease-in-out 0.4s",
                }} >
                    <div dangerouslySetInnerHTML={createMarkup()} />


                    {isLoadingPlaces && data != null ? (
                        <div>
                            {lengthData(data) > 0 && (
                                <h4 style={{

                                    marginTop: 10
                                }}>По стопам истории</h4>
                            )}

                            {
                                Object.keys(data).map((key, index) => {
                                    return (
                                        <div onClick={() => {
                                            history.push(`/p/${data[key].id}`)
                                        }} >

                                            <PlaceItemView key={index} item={data[key]}  />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (<></>)}
                </div>
            </div>



        </div>
    )
}


