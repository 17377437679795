import React from "react"

export default function CardTransitionView ( props ) {
    const { selected, position: {cardPosition, setCardPosition},animation : {animation, setAnimation} , animationEnd: {animationEnd, setAnimationEnd}, present: {present, setPresent}} = props
    return(
        <div style={{opacity: animationEnd ? 0 : 1}}>
            <div
                style={{
                    backgroundColor: "white",
                    position: "fixed",
                    top: animation ? 0 : cardPosition.y , // fix scroll offset
                    left: animation ? 0 : cardPosition.x,
                    width: animation ? "100%" : cardPosition.width,
                    height: animation ? "100%" : cardPosition.height,
                    borderRadius: animation ? 0 : 15,
                    zIndex: 99995,
                    transition: "0.4s",

                }}
            >
            </div>
            <img
                src={`https://apimipt.4129.ru/get-file.php?id=${selected.summaryImage.signatures[0].id}&dir=${selected.summaryImage.signatures[0].dir}&path=${selected.summaryImage.signatures[0].path}&style=thumbnail`}

                style={{
                    backgroundColor: "gray",
                    objectFit: "cover",
                    position: "fixed",
                    top: animation ? 0 : cardPosition.y, // fix scroll offset
                    left: animation ? 0 : cardPosition.x,
                    width: animation ? "100%" : cardPosition.width,
                    height: cardPosition.height,
                    borderRadius: animation ? 0 : 15,
                    zIndex: 99996,
                    transition: "0.4s"
                }} />
            <h5 style={{
                position: "absolute",
                top: animation ? 30 : cardPosition.y + 30,
                left: animation ? 10 : 30,
                color: "#FFFFFF",
                zIndex: 99997,
                width: "80%",
                transition: "0.4s"
            }}>{selected.title}</h5>

        </div>
    )
}
