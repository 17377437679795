import React, {useEffect, useRef, useState, useMemo, useContext} from "react"
import axios from "axios"
import {headers_token} from "../config";
import { Carousel } from "react-bootstrap";
import Card from "../components/Card";
import PlaceItemView from "../components/PlaceItemView";
import appstore from "./../assets/appstore.png";
import google from "./../assets/google.png";
import { AiFillCloseCircle } from "react-icons/ai";
import {  useHistory, useLocation } from "react-router-dom"
import {decode} from 'html-entities';
import MainContext from "../Context"
import "./style.css"
import {Helmet} from "react-helmet";
import Language from "../Language/Language";
const styles = {
    CarouselItem: {
        height: "240px",
    },
};
// [заголовки страниц / карточек] - МФТИ

export default function PlaceDetail ( props ) {
    const mainContext = useContext(MainContext);
    const location = useLocation();
    const tagsList = JSON.parse(localStorage.getItem("tags"))
    let history = useHistory();
    const [place, setPlace] = useState(null)
    const [dataTags, setDataTags] = useState(null);
    const [dataHistory, setDataHistory] = useState(null)
    const fullcard = useRef(null);
    const [itemLegend, setItemLegend] = useState(null)
    const [places, setPlaces] = useState(null)
    const [historyIsLoading, setHistoryIsLoading] = useState(false)
    const [expandIndex, setExpandIndex] = useState(null);
    const [mainTag, setMainTag] = useState(null)
    const closeCard = () => {
        setTransition(false);
        setShowText(false);
        setTimeout(() => {
            setExpand(false);
        }, 400);
    };
    const fetchHistoryFromId = async () => {
        const tmp = [];
        for (let i = 0; i < place.posts.length; i++) {
            const response = await axios.get(
                `https://apimipt.4129.ru/api/history?filter[id]=${place.posts[i].id}`,
                headers_token
            );
            const commit = response.data.data.paginator;


            for(let h in commit){
                tmp.push(commit[h])
            }
        }

        setDataHistory(tmp)
        setHistoryIsLoading(true)
    }
    const getNavigationPlace = async(places) => {
        let url = "https://apimipt.4129.ru/api/place?";
        for (let i = 0; i < places.length; i++) {
            url += `filter[id][]=${places[i].id}&`;
        }
        const response = await axios(url, headers_token);
        const commit = response.data.data.paginator;
        setPlaces(commit);
    }
    const [expand, setExpand] = useState(false);
    const [transition, setTransition] = useState(false);
    const [showText, setShowText] = useState(false);
    const [offset, setOffset] = useState({});
    const [tagsIsLoaded, setTagsIsLoaded] = useState(false)
    async function getPlaceFromId (id) {
        try {
            const response = await axios.get(`https://apimipt.4129.ru/api/place?filter[id]=${id}`, headers_token )
            const commit = response.data.data.paginator
            let FindPlace;
            for(let key in commit){
                if  ( key == id ) {
                    FindPlace = commit[key]
                }
            }
            setPlace(FindPlace)
        } catch(e) {
            console.log(e)
        }
    }
    useMemo(() => {
        if (expand && itemLegend != null){
            getNavigationPlace(itemLegend.navigatePlaces)
        }

    }, [expand, itemLegend])
    useMemo(() => {
        if (!place) {
        } else {
            // fetchMainTag()
            // fetchTag( place.tags);
            let tagPlace = place.tags
            // console.log(mainContext.Tags)
            let tagAll = tagsList
            let findsTags = [];
            // console.log(tagPlace)
            // console.log(tagAll)
            for(let j = 0; j < tagPlace.length; j++){
                Object.keys(tagAll).map((key, i) => {
                    if ( tagPlace[j].id == tagAll[key].id && tagAll[key].type == "info") {
                        findsTags.push({
                            name: tagAll[key].name,
                            image: `https://apimipt.4129.ru/get-file.php?id=${tagAll[key].image.signatures[0].id}&dir=${tagAll[key].image.signatures[0].dir}&path=${tagAll[key].image.signatures[0].path}&style=thumbnail`
                        })
                    }
                    if ( tagPlace[j].id == tagAll[key].id && tagAll[key].type == "use") {

                        setMainTag(tagAll[key].name)
                    }
                })
            }

            findsTags.sort((a, b) => a.name.localeCompare(b.name));

            setDataTags(findsTags)

            fetchHistoryFromId()
        }
    }, [place]);
    async function getAllTags (idPath) {
        let URL = "https://apimipt.4129.ru/api/place-tag?perpage=1000"
        try {
            const response = await axios.get(
                URL,
                headers_token
            );
            localStorage.setItem("tags", JSON.stringify(response.data.data.paginator))
            getPlaceFromId(idPath)
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        const URL = location.pathname
        const idPath = URL.slice(3)

        if (!tagsList) {
            getAllTags(idPath)
        } else {
            getPlaceFromId(idPath)
        }
    }, [])


    function checkLanguage (type) {

        const lng = localStorage.getItem("language")
        let word;
        if (type == "DownloadBlock") {
            if ( lng == "ru" ) {
                word = Language.ru.DownloadBlock
            }
            else if ( lng == "en" ) {
                word = Language.en.DownloadBlock
            }
            else if ( lng == "es" ) {
                word = Language.es.DownloadBlock
            }
            else {
                word = Language.en.DownloadBlock
            }
        }

        return word
    }
    function titleLang () {

        const lng = localStorage.getItem("language")
        let word;
        if ( lng == "ru" ) {
            word = "МФТИ"
        }
        else if ( lng == "en" ) {
            word = "MIPT"
        }
        else if ( lng == "es" ) {
            word = "MIPT"
        }
        else {
            word = "MIPT"
        }

        return word
    }
    function lengthData (data) {
        let array = []
        for(let item in data) {
            array.push(item)
        }
        return array.length
    }
    return(
        <>

            {!place ? (
                <div>Загрузка</div>
            ) : (

                <div style={{paddingTop: 45}}>
                    <Helmet>
                        <title>{place.name} - {titleLang()}</title>
                    </Helmet>
                    <Carousel controls={true} indicators={false}>
                        {place.image.signatures.map((item, index) => (
                            <Carousel.Item style={styles.CarouselItem} key={index}>
                                <img
                                    className="d-block w-100"
                                    src={`https://apimipt.4129.ru/get-file.php?id=${item.id}&dir=${item.dir}&path=${item.path}&style=thumbnail`}
                                    alt="First slide"
                                    height="100%"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <div
                        style={{
                            background: "#0069B4",
                            color: "#FFFFFF",
                            textAlign: "center",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                        }}
                    >
                        <h3 style={{ fontSize: "18px" }}>
                            {checkLanguage("DownloadBlock")}
                        </h3>
                        <div>
                            <a href="#" style={{ marginRight: 10 }}>
                                <img src={google} />
                            </a>
                            <a href="#">
                                <img src={appstore} />
                            </a>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "14px",
                        }}
                    >
            <span style={{ color: "#000000", fontSize: "15px", fontWeight: "bold" }}>
              {place.name}
            </span>
                        <span style={{ color: "#0069B4", fontSize: "14px" }}>
              {!mainTag ? "—" : mainTag}
            </span>
                        <span style={{ color: "#000000", fontSize: "14px" }}>
              {!place.place ? "---" : place.place.address}
            </span>
                        {place.needPermit > 0 ? (
                            <div>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0)">
                                        <path
                                            d="M17.7083 17.5H2.29172C1.02753 17.5 0 16.4725 0 15.2083V4.79172C0 3.52753 1.02753 2.5 2.29172 2.5H17.7083C18.9725 2.5 20 3.52753 20 4.79172V15.2083C20 16.4725 18.9725 17.5 17.7083 17.5ZM2.29172 3.75C1.71753 3.75 1.25 4.21753 1.25 4.79172V15.2083C1.25 15.7825 1.71753 16.25 2.29172 16.25H17.7083C18.2825 16.25 18.75 15.7825 18.75 15.2083V4.79172C18.75 4.21753 18.2825 3.75 17.7083 3.75H2.29172Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M6.25003 10C5.10165 10 4.16675 9.06494 4.16675 7.91672C4.16675 6.76834 5.10165 5.83328 6.25003 5.83328C7.39841 5.83328 8.33331 6.76834 8.33331 7.91672C8.33331 9.06494 7.39841 10 6.25003 10ZM6.25003 7.08328C5.79089 7.08328 5.41675 7.45743 5.41675 7.91672C5.41675 8.37585 5.79089 8.75 6.25003 8.75C6.70917 8.75 7.08331 8.37585 7.08331 7.91672C7.08331 7.45743 6.70917 7.08328 6.25003 7.08328Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M9.375 14.1667C9.03 14.1667 8.75 13.8867 8.75 13.5417V13.125C8.75 12.5508 8.28247 12.0833 7.70828 12.0833H4.79172C4.21753 12.0833 3.75 12.5508 3.75 13.125V13.5417C3.75 13.8867 3.47 14.1667 3.125 14.1667C2.78 14.1667 2.5 13.8867 2.5 13.5417V13.125C2.5 11.8608 3.52753 10.8333 4.79172 10.8333H7.70828C8.97247 10.8333 10 11.8608 10 13.125V13.5417C10 13.8867 9.72 14.1667 9.375 14.1667Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M16.875 7.5H12.2917C11.9467 7.5 11.6667 7.22 11.6667 6.875C11.6667 6.53 11.9467 6.25 12.2917 6.25H16.875C17.22 6.25 17.5 6.53 17.5 6.875C17.5 7.22 17.22 7.5 16.875 7.5Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M16.875 10.8333H12.2917C11.9467 10.8333 11.6667 10.5533 11.6667 10.2083C11.6667 9.86328 11.9467 9.58328 12.2917 9.58328H16.875C17.22 9.58328 17.5 9.86328 17.5 10.2083C17.5 10.5533 17.22 10.8333 16.875 10.8333Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M16.875 14.1667H12.2917C11.9467 14.1667 11.6667 13.8867 11.6667 13.5417C11.6667 13.1967 11.9467 12.9167 12.2917 12.9167H16.875C17.22 12.9167 17.5 13.1967 17.5 13.5417C17.5 13.8867 17.22 14.1667 16.875 14.1667Z"
                                            fill="#999999"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span
                                    style={{
                                        color: "#000000",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                    }}
                                >
                Для посещения требуется пропуск
              </span>
                            </div>
                        ) : <></>}
                    </div>
                    {!dataTags && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <span>Загрузка тегов...</span>
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            overflow: "scroll",
                        }}
                    >
                        {!dataTags ? (
                            <></>
                        ) : (
                            dataTags.map((item,index) => {
                                // console.log(`https://apimipt.4129.ru/get-file.php?id=${item.image.signatures[0].id}&dir=${item.image.signatures[0].dir}&path=${item.image.signatures[0].path}&style=thumbnail`);
                                return(
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginRight: "10px",
                                                width: "87px",
                                                height: "60px",
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginLeft: 20,
                                                    justifyContent: "space-between",
                                                    height: "100%",
                                                }}
                                            >
                        <span
                            style={{
                                color: "#999999",
                                fontSize: "10px",
                                paddingBottom: "4px",
                                display: "flex",
                                lineHeight: 1,
                            }}
                        >
                          {item.name}
                        </span>
                                                <img
                                                    src={item.image}
                                                    style={{
                                                        width: 28,
                                                        height: 28,
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: 1,
                                                height: 43,
                                                backgroundColor: "#cccccc",
                                                marginLeft: 12,
                                            }}
                                        />
                                    </div>
                                )
                            })




                        )}
                    </div>

                    <div style={{ padding: "14px" }} className={"fixText"} dangerouslySetInnerHTML={{ __html: place.description }}>
                        {/*{clearText(place.description.replace(/<[^>]+>/g, ""))}*/}
                        {/*{decode(place.description.replace(/<[^>]+>/g, ""))}*/}

                    </div>

                    <div style={{marginTop: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

                        {dataHistory != null ? (
                            dataHistory.length > 0 && (
                                <h4 style={{

                                    textAlign: "left",
                                    width: "94%",
                                    marginBottom: 20
                                }}>Ходят слухи</h4>
                            )
                        ) : <></>

                        }
                        {dataHistory != null ? (
                            dataHistory.map((item,index) => (
                                <Card
                                    key={index}
                                    data={item}
                                    selectCard={(top, left) => {
                                        setExpandIndex(index);
                                        setItemLegend(item)
                                        setOffset({ top: top, left: left });

                                        setExpand(true);

                                        setTimeout(() => {
                                            setTransition(true);
                                            setShowText(true);
                                            setShowText(true);
                                        }, 50);
                                    }}
                                />
                            ))
                        ) : <></>}
                    </div>

                    {expand && itemLegend != null ? (
                        <div
                            ref={fullcard}
                            style={{
                                zIndex: 9999,
                                position: "fixed",
                                top: transition ? 0 : offset.top,
                                left: transition ? 0 : offset.left,
                                width: transition ? window.innerWidth : window.innerWidth - 30,
                                height: transition ? "100%" : "430px",
                                backgroundColor: "#FFFFFF",
                                overflowY: "scroll",
                                borderRadius: transition ? 0 : "15px",
                                boxShadow: "0 0 14px rgba(0,0,0,0.75)",
                                transition: "ease 0.3s",

                            }}
                        >

                            <div
                                style={{
                                    position: "relative",
                                    backgroundColor: "#CCCCCC",
                                    width: "100%",
                                    height: "430px",
                                    textAlign: "right",
                                    // borderRadius: transition ? 0 : "15px",
                                    // transition: "ease-in-out 0.4s",
                                }}
                            >
                                <img
                                    src={`https://apimipt.4129.ru/get-file.php?id=${itemLegend.summaryImage.signatures[0].id}&dir=${itemLegend.summaryImage.signatures[0].dir}&path=${itemLegend.summaryImage.signatures[0].path}&style=thumbnail`}
                                    style={{
                                        width: "100%",
                                        height: "430px",
                                        //   borderRadius: transition ? 0 : "15px",
                                        //   transition: "ease-in-out 0.4s",
                                    }}
                                />
                                <h4
                                    style={{
                                        position: "absolute",
                                        color: "white",
                                        top: 20,
                                        left: 10,
                                        textAlign: "left",
                                        width: window.innerWidth - 60,
                                        fontSize: 18
                                    }}
                                >
                                    {itemLegend.title}
                                </h4>
                                <h2
                                    style={{
                                        position: "absolute",
                                        top: 15,
                                        right: 20,
                                        zIndex: 9999999,
                                        opacity: transition ? 1 : 0,
                                        transition: "ease-in-out 0.4s",
                                        color: "#FFFFFF"
                                    }}
                                    onClick={() => closeCard()}
                                >
                                    <AiFillCloseCircle />


                                </h2>
                            </div>


                            <div style={{
                                backgroundColor: "#FFFFFF",
                                padding: "10px",
                                opacity: showText ? 1 : 0,
                                transition: "ease-in-out 0.4s"
                            }} dangerouslySetInnerHTML={{ __html: itemLegend.content }}>

                            </div>
                            {/*<p*/}
                            {/*    style={{*/}
                            {/*        backgroundColor: "#FFFFFF",*/}
                            {/*        padding: "10px",*/}
                            {/*        opacity: showText ? 1 : 0,*/}
                            {/*        transition: "ease-in-out 0.4s"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    /!*{clearText(itemLegend.content.replace(/<[^>]+>/g, ""))}*!/*/}
                            {/*    /!*{decode(itemLegend.content.replace(/<[^>]+>/g, ""))}*!/*/}
                            {/*</p>*/}
                            <div style={{paddingLeft: "10px",
                                paddingRight: "10px"}}>
                                {!places ? <span>Загрузка</span> : (
                                    <div>
                                        {
                                            lengthData(places) > 0 && (
                                                <h4 style={{

                                                    marginTop: 10
                                                }}>По стопам истории</h4>
                                            )
                                        }
                                        {Object.keys(places).map((key, i) => {
                                            return (
                                                <div onClick={() => {

                                                    history.push(`/p/${places[key].id}`)
                                                    window.location.reload()
                                                }} >
                                                    <PlaceItemView key={i}
                                                                   item={places[key]} expandHendler={{expand, setExpand}} />
                                                </div>

                                            )
                                        })   }
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : <></>}
                </div>
            )}

        </>
    )
}
