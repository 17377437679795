import React, { useState, useRef, useEffect, useMemo } from "react";
import CardView from "../components/Card/CardView/index"
import CardDetail from "../components/Card/CardDetailView/index"
import CardTransitionView from "../components/Card/CardTransitionView/index"
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import UrlCardDetail from "../components/Card/UrlCardDetal/index"
import { headers_token } from "../config";
import {decode} from 'html-entities';
import { Helmet } from 'react-helmet'
import Language from "../Language/Language";
// [заголовки страниц / карточек] - МФТИ




const Legends = () => {
  let history = useHistory();
  const location = useLocation();
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  // CardAnimation
  const [present, setPresent] = useState(false)
  const [cardPosition, setCardPosition] = useState(null)
  const [animation, setAnimation] = useState(false)
  const [animationEnd, setAnimationEnd] = useState(false)
  // -//-
  const [selectedTransitionUrl, setSelectedTransitionUrl] = useState(false)
  const [transitionUrl, setTransitionUrl] = useState(null)
  const clearText = (inputText) => {
    let matches = inputText.replace(/&[a-zA-Z0-9_\d]+;/g, "");
    return matches;
  };
  function getTitlte () {
    const lng = localStorage.getItem("language")
    let word;
    // Leyendas de Phystech
    // Phystech legends
    if ( lng == "ru" ) {
      word = 'Легенды Физтеха - МФТИ'
    }
    else if ( lng == "en" ) {
      word = 'Phystech legends - MIPT'
    }
    else if ( lng == "es" ) {
      word = 'Leyendas de Phystech - MIPT'
    }
    else {
      word = 'Phystech legends - MIPT'
    }
    return word
  }
  const fetchData = async () => {
    try {
      const language = localStorage.getItem("language")
      let idLanguage;
      if ( language == undefined || language == null ) { idLanguage = 1}
      if ( language == "ru" ) { idLanguage = 1}
      if ( language == "en" ) { idLanguage = 2}
      if ( language == "es" ) { idLanguage = 3}
      if ( language == "en" ) { idLanguage = 2}
      const response = await axios.get(

          `https://apimipt.4129.ru/api/history?perpage=10000&filter[language][]=${idLanguage}`,
          headers_token
      );

      const { paginator } = response.data.data;
      console.log(paginator)
      setData(paginator);
      setIsLoading(true)
    } catch (error) {
      console.log(error);
    }
  };
  const Mounted = async () => {
    fetchData();
  };
  async function getHistoryQr (id) {
    try {
      const response = await axios.get(`https://apimipt.4129.ru/api/history?filter[id][]=${id}`, headers_token);
      const commit = response.data.data.paginator;
      console.log("commit", commit)
      const dataHistory = Object.values(commit);
      console.log("dataHistory", dataHistory[0] )
      setTransitionUrl(dataHistory[0])
      setSelectedTransitionUrl(true)
    }
    catch (e) {
      console.log(e)
    }
  }
  useMemo(() => {
    if ( data != null ) {
      const URL = location.pathname
      const idPath = URL.slice(9)
      if ( idPath.length > 0 ) {
        getHistoryQr(idPath)
        // Object.keys(data).map((item, index) => {
        //   if ( data[item].id == idPath ) {
        //     setTransitionUrl(data[item])
        //   }
        // })
        // setSelectedTransitionUrl(true)
      }
    }
  }, [data])
  useEffect(() => {

    Mounted()


  }, []);

  function handlePressCard (position, selectedItem) {
    history.push(`/h/${selectedItem.id}`)
    setSelected(selectedItem)
    setCardPosition(position)
    setPresent(true)
    setTimeout(() => {
      setAnimation(true)
    }, 50)
    setTimeout(() => {
      setAnimationEnd(true)
    }, 1000)
  }
  function checkLanguage (type) {

    const lng = localStorage.getItem("language")
    let word;
    if (type == "isLoading") {
      if ( lng == "ru" ) {
        word = Language.ru.isLoading
      }
      else if ( lng == "en" ) {
        word = Language.en.isLoading
      }
      else if ( lng == "es" ) {
        word = Language.es.isLoading
      }
      else {
        word = Language.en.isLoading
      }
    }

    return word
  }
  return (
      <>
        <Helmet>
          <title>{ present ? selected.title : getTitlte()}</title>
        </Helmet>
        {isLoading ? (
            <div style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",

            }}>
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "scroll",
                paddingTop: 75,
              }}>
                {Object.keys(data).map((item, index) => {

                  return (
                      <CardView key={index}  image={
                        `https://apimipt.4129.ru/get-file.php?id=${data[item].summaryImage.signatures[0].id}&dir=${data[item].summaryImage.signatures[0].dir}&path=${data[item].summaryImage.signatures[0].path}&style=thumbnail`
                      } positionCard={(position) => handlePressCard(position, data[item])} title={data[item].title} />
                  )
                })}

              </div>
              {present && selected && (
                  <>
                    <CardTransitionView selected={selected} position={{cardPosition, setCardPosition}} animation={{animation, setAnimation}} animationEnd={{animationEnd, setAnimationEnd}} present={{present, setPresent}} />
                    <CardDetail selected={selected} position={{cardPosition, setCardPosition}} animation={{animation, setAnimation}} animationEnd={{animationEnd, setAnimationEnd}} present={{present, setPresent}} />
                  </>
              )}
              {selectedTransitionUrl && (
                <UrlCardDetail selected={{transitionUrl, setTransitionUrl}} transition={{
                  selectedTransitionUrl,
                  setSelectedTransitionUrl
                }} />
              )}
            </div>
        ) : (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: 100
            }}>
              <span style={{color: "#a6a6a6",fontSize: 12,padding: 10,}}>{checkLanguage("isLoading")}...</span>
            </div>
        )}

      </>
  );
};

export default Legends;















// import React, { useState, useRef, useEffect, useMemo } from "react";
// import axios from "axios";
// import { AiFillCloseCircle } from "react-icons/ai";
// import Card from "../components/Card";
// import PlaceItemView from "../components/PlaceItemView"
// import { headers_token } from "../config";
// import {  useHistory } from "react-router-dom"
// import {decode} from 'html-entities';
// import { Helmet } from 'react-helmet'
// // [заголовки страниц / карточек] - МФТИ
// const TITLE = 'Легенды Физтеха - МФТИ'
// const styles = {
//   container: {
//     display: "flex",
//     width: "100%",
//     flexDirection: "column",
//     // alignItems: "center"
//     padding: "15px",
//   },
// };
//
// const Legends = () => {
//   let history = useHistory();
//   const [data, setData] = useState([]);
//   const fullcard = useRef(null);
//   const [expand, setExpand] = useState(false);
//   const [transition, setTransition] = useState(false);
//   const [showText, setShowText] = useState(false);
//   const [offset, setOffset] = useState({});
//   const [expandIndex, setExpandIndex] = useState(null);
//   const [itemLegend, setItemLegend] = useState(null)
//
//   const [places, setPlaces] = useState(null)
//   const clearText = (inputText) => {
//     let matches = inputText.replace(/&[a-zA-Z0-9_\d]+;/g, "");
//     return matches;
//   };
//   const closeCard = () => {
//     setTransition(false);
//     setShowText(false);
//     setTimeout(() => {
//       setExpand(false);
//     }, 400);
//   };
//   const getData = async () => {
//     try {
//       const language = localStorage.getItem("language")
//       let idLanguage;
//       if ( language == undefined || language == null ) { idLanguage = 1}
//       if ( language == "ru" ) { idLanguage = 1}
//       if ( language == "en" ) { idLanguage = 2}
//       if ( language == "es" ) { idLanguage = 3}
//       if ( language == "en" ) { idLanguage = 2}
//       const hesponse = await axios.get(
//
//         `https://apimipt.4129.ru/api/history?perpage=10000&filter[language][]=${idLanguage}`,
//         headers_token
//       );
//       const {paginator} = hesponse.data.data;
//       setData(paginator);
//     } catch (e) {
//       console.log(e);
//     }
//   };
//   const getNavigationPlace = async(places) => {
//     let url = "https://apimipt.4129.ru/api/place?";
//     for (let i = 0; i < places.length; i++) {
//       url += `filter[id][]=${places[i].id}&`;
//     }
//
//     const response = await axios(url, headers_token);
//     const commit = response.data.data.paginator;
//     // console.log(commit);
//     setPlaces(commit);
//   }
//   useMemo(() => {
//     if (expand && itemLegend != null){
//       getNavigationPlace(itemLegend.navigatePlaces)
//     }
//
//   }, [expand, itemLegend])
//   useEffect(() => {
//     getData();
//   }, []);
//   return (
//     <>
//       <Helmet>
//         <title>{ TITLE }</title>
//       </Helmet>
//       <div>
//         <div
//           style={{
//             zIndex: 1,
//             display: "flex",
//             width: "100%",
//             height: "100%",
//             flexDirection: "column",
//             alignItems: "center",
//             paddingTop: "10px",
//             overflow: expand ? "hidden" : "scroll",
//           }}
//         >
//           {Object.keys(data).map((key, i) => {
//           return (
//             <Card
//               key={i}
//               data={data[key]}
//               selectCard={(top, left) => {
//                 setExpandIndex(i);
//                 setItemLegend(data[key])
//                 setOffset({ top: top, left: left });
//
//                 setExpand(true);
//
//                 setTimeout(() => {
//                   setTransition(true);
//                   setShowText(true);
//                   setShowText(true);
//                 }, 50);
//               }}
//             />
//           )
//           })}
//
//         </div>
//         {expand && itemLegend != null ? (
//           <div
//           ref={fullcard}
//           style={{
//             zIndex: 9999,
//             position: "fixed",
//             top: transition ? 0 : offset.top,
//             left: transition ? 0 : offset.left,
//             width: transition ? window.innerWidth : window.innerWidth - 30,
//             height: transition ? "100%" : "430px",
//             backgroundColor: "#FFFFFF",
//             overflowY: "scroll",
//             borderRadius: transition ? 0 : "15px",
//             boxShadow: "0 0 14px rgba(0,0,0,0.75)",
//             transition: "ease-in-out 0.4s",
//
//           }}
//         >
//
//           <div
//             style={{
//               position: "relative",
//               backgroundColor: "#CCCCCC",
//               width: "100%",
//               height: "430px",
//               textAlign: "right",
//             }}
//           >
//             <img
//               src={`https://apimipt.4129.ru/get-file.php?id=${itemLegend.summaryImage.signatures[0].id}&dir=${itemLegend.summaryImage.signatures[0].dir}&path=${itemLegend.summaryImage.signatures[0].path}&style=thumbnail`}
//               style={{
//                 width: "100%",
//                 height: "430px",
//               }}
//             />
//             <h4
//               style={{
//                 position: "absolute",
//                 color: "white",
//                 top: 20,
//                 left: 10,
//                 textAlign: "left",
//                 width: window.innerWidth - 60,
//                 fontSize: 18
//               }}
//             >
//               {itemLegend.title}
//             </h4>
//             <h2
//               style={{
//                 position: "absolute",
//                 top: 15,
//                 right: 20,
//                 zIndex: 9999999,
//                 opacity: transition ? 1 : 0,
//                 transition: "ease-in-out 0.4s",
//                   color: "#FFFFFF"
//               }}
//               onClick={() => closeCard()}
//             >
//                 <AiFillCloseCircle />
//
//
//             </h2>
//           </div>
//
//         <div style={{
//           backgroundColor: "#FFFFFF",
//           padding: "10px",
//           opacity: showText ? 1 : 0,
//           transition: "ease-in-out 0.4s"
//         }} dangerouslySetInnerHTML={{ __html: itemLegend.content }}>
//
//         </div>
//
//           <div style={{paddingLeft: "10px",
//     paddingRight: "10px"}}>
//             {!places ? <span>Загрузка</span> : (
//               Object.keys(places).map((key, i) => {
//                 return (
//                   <div onClick={() => {
//                     closeCard()
//                     history.push(`/p/${places[key].id}`)
//                   }} >
//                     <PlaceItemView key={i}
//                                    item={places[key]} expandHendler={{expand, setExpand}} />
//                   </div>
//
//                 )
//               })
//             )}
//           </div>
//         </div>
//         ) : <></>}
//       </div>
//     </>
//   );
// };
//
// export default Legends;
