import React, { useEffect } from "react";
import axios from "axios";
import { headers_token } from "../../config";
import { useHistory, useLocation } from "react-router-dom";

export default function QrCode(props) {
  let history = useHistory();
  const location = useLocation();
  async function fetchQrData() {
    const URL = location.pathname;
    let qrId = URL.split("/qr/")[1];
    try {
      const response = await axios.get(
        "https://apimipt.4129.ru/api/qr-code?perpage=1000",
        headers_token
      );
      const { paginator } = response.data.data;
      const data = Object.values(paginator);
      let resFindCode;
      for (let i = 0; i < data.length; i++) {
        if (qrId == data[i].code) {
          if (data[i].places.length > 0) {
            resFindCode = { places: data[i].places, type: "places" };
          }
          if (data[i].posts.length > 0) {
            resFindCode = { posts: data[i].posts, type: "posts" };
          }
        }
      }

      if (!resFindCode) {
        return;
      } else {
        // const lng = localStorage.getItem("language")

        if (resFindCode.type == "places") {
          // history.push(`/p/${resFindCode.places[0].id}`)
        }
        if (resFindCode.type == "posts") {
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function init() {
    const lng = localStorage.getItem("language")
    const lngId = lng == "ru" ? "1" : lng == "en" ? "2" : lng == "es" ? "3" : "2"
    const URL = location.pathname;
    let qrId = URL.split("/qr/")[1];
    try {
      const response = await axios.get(
        "https://apimipt.4129.ru/api/qr-code?perpage=1000",
        headers_token
      );
      const { paginator } = response.data.data;
      const data = Object.values(paginator);
      let resFindCode;
      for (let i = 0; i < data.length; i++) {
        if (qrId == data[i].code) {
          resFindCode = data[i];
        }
      }
      let places = resFindCode.places;
      let posts = resFindCode.posts;
      console.log("data: ", { places, posts });
      if (places.length > 0) {
        let urlString = "https://apimipt.4129.ru/api/place?";
        for (let i = 0; i < places.length; i++) {
          if (i !== places.length - 1) {
            urlString += `filter[id][]=${places[i].id}&`;
          } else {
            urlString += `filter[id][]=${places[i].id}`;
          }
        }
        const response = await axios.get(urlString, headers_token);
        const commit = response.data.data.paginator;
        const dataPlaces = Object.values(commit);
        let placeCurrentLanguage;
        dataPlaces.map((el) => {
            if ( el.language.id == lngId ) {
                placeCurrentLanguage = el.id
            }
        });
        if (!placeCurrentLanguage) {
            dataPlaces.map((el) => {
                if ( el.language.id == "2" ) {
                    placeCurrentLanguage = el.id
                }
            })
        }
        console.log("placeCurrentLanguage", placeCurrentLanguage)
        history.push(`/p/${placeCurrentLanguage}`)
      }
      if (posts.length > 0) {
        let urlString = "https://apimipt.4129.ru/api/history?";
        for (let i = 0; i < posts.length; i++) {
          if (i !== posts.length - 1) {
            urlString += `filter[id][]=${posts[i].id}&`;
          } else {
            urlString += `filter[id][]=${posts[i].id}`;
          }
        }
        const response = await axios.get(urlString, headers_token);
        const commit = response.data.data.paginator;
        const dataHistory = Object.values(commit);
        let historyCurrentLanguage;
        dataHistory.map((el) => {
            if ( el.language.id == lngId ) {
                historyCurrentLanguage = el.id
            }
        });
        if (!historyCurrentLanguage) {
            dataHistory.map((el) => {
                if ( el.language.id == "2" ) {
                    historyCurrentLanguage = el.id
                }
            })
        }
        console.log("historyCurrentLanguage", historyCurrentLanguage)
        history.push(`/history/${historyCurrentLanguage}`)
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    init();
  }, []);
  return <div></div>;
}
