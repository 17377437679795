import React, { useRef } from "react";

const Card = ({ selectCard, data }) => {
  const boxCard = useRef(null);
  // console.log(data);
  return (
    <div
      ref={boxCard}
      style={{ border: "none", backgroundColor: "none" }}
      onClick={() =>
        selectCard(
          boxCard.current.offsetTop - window.pageYOffset,
          boxCard.current.offsetLeft
        )
      }
      style={{
        position: "relative",
        marginBottom: "20px"
      }}
    >
      <img
        src={`https://apimipt.4129.ru/get-file.php?id=${data.summaryImage.signatures[0].id}&dir=${data.summaryImage.signatures[0].dir}&path=${data.summaryImage.signatures[0].path}&style=thumbnail`}
        style={{
          backgroundColor: "#CCCCCC",
          marginBottom: "10px",
          width: window.innerWidth - 30,
          borderRadius: "15px",
          height: "430px",
          boxShadow: "0 0 14px rgba(0,0,0,0.35)"
        }}
      />

      <h4
        style={{
          position: "absolute",
          color: "white",
          top: 20,
          left: 10,
          width: window.innerWidth - 60,
          fontSize: 18
        }}
      >
        {data.title}
      </h4>
    </div>
  );
};

export default Card;
