import React, { useEffect, useState, useMemo, useContext } from "react";
import PlaceItem from "../components/PlaceItem";
import { headers_token } from "../config";
import PlaceItemView from "./../components/PlaceItemView"
import axios from "axios";
import {useHistory} from "react-router-dom";
import { Helmet } from 'react-helmet'
import Language from "../Language/Language";
// [заголовки страниц / карточек] - МФТИ

const styles = {
  PlaceWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "50px"
  },
};
function kmLng () {
  const lng = localStorage.getItem("language")
  let km;
  if ( lng == "ru" ) {
    km = "км"
  }
  else if ( lng == "en" ) {
    km = "km"
  }
  else if ( lng == "es" ) {
    km = "km"
  }
  else {
    km = "km"
  }
  return km
}
function mLng () {
  const lng = localStorage.getItem("language")
  let m;
  if ( lng == "ru" ) {
    m = "метров"
  }
  else if ( lng == "en" ) {
    matchMedia = "meters"
  }
  else if ( lng == "es" ) {
    m = "metros"
  }
  else {
    m = "meters"
  }
  return m
}
const getDistance = (lat1, lon1, lat2, lon2) => {
  
  let radius = 6371;
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = parseFloat(radius) * c;
  let res;
  if (d < 1) {
  	res = d * 1000
  } else {
  	res = d
  }
  return d;
};
const deg2rad = (number) => {
  return (number * Math.PI) / 180;
};
const Place = () => {

  let history = useHistory();
  const [newData, setIsNewData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refactoring, setRefactoring] = useState(false)
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoadingLast, setIsLoadingLast] = useState(false);
  const [rerender, setRerender] = useState(false)
  const [endData, setEndData] = useState(false)
  const [location, setLocation] = useState(null);
  const [tmp, setTmp] = useState(null)
  const [locationData, setLocationData] = useState(null)
  const [sortedType, setSortedType] = useState("id")
  navigator.geolocation.getCurrentPosition(function (position) {
    setLocationData({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    })
  });
  function getTitlte () {
    const lng = localStorage.getItem("language")
    let word;
    if ( lng == "ru" ) {
      word = 'Каталог мест - МФТИ'
    }
    else if ( lng == "en" ) {
      word = 'Places catalog - MIPT'
    }
    else if ( lng == "es" ) {
      word = 'Catálogo de lugares - MIPT'
    }
    else {
      word = 'Places catalog - MIPT'
    }
    return word
  }
  const fetchData = async () => {
    try {
      const language = localStorage.getItem("language")
      let idLanguage;
      if ( language == undefined || language == null ) { idLanguage = 1}
      if ( language == "ru" ) { idLanguage = 1}
      if ( language == "en" ) { idLanguage = 2}
      if ( language == "es" ) { idLanguage = 3}
      if ( language == "en" ) { idLanguage = 2}
      const response = await axios.get(

        `https://apimipt.4129.ru/api/place?perpage=10000&filter[language][]=${idLanguage}`,
        headers_token
      );

      const { paginator } = response.data.data;
      
      setData(paginator);
      setIsLoading(true)
    } catch (error) {
      console.log(error);
    }
  };


  const initLocation = async () => {

    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    // console.log(tag[Object.keys(tag)[0]]);
  }
  const Mounted = async () => {
    initLocation()
    fetchData();
  };

  useMemo(() => {
    
      
      if(data != null) {
        if (locationData != null) {
          if (sortedType == "id"){
            let ref = []
          const commit = Object.values(data)
          for (let i = 0; i < commit.length; i++){
            let d = getDistance(locationData.latitude, locationData.longitude, commit[i].place.latitude,commit[i].place.longitude)
            let drender;
            if (d < 1) {
              drender = `${parseInt(d * 1000)} ${mLng()}`
            } else {
              drender = `${parseInt(d)} ${kmLng()}`
            }
            ref.push({...commit[i], distance: d, drender: drender})
          }
          let s = ref.sort((x,y) => x.distance > y.distance)
          console.log("SORTED ARRAY: ", s);

          setIsNewData(s)
          setSortedType("distance")
          setRefactoring(true)
          }
        } else {
          if (sortedType == "distance" || sortedType == "id") {
            const commit = Object.values(data)
            let s = commit.sort((x,y) => x.id > y.id)
            console.log("SORTED BY ID: ", s)
            setIsNewData(s)
            setSortedType("id")
            setRefactoring(true)
          }
        }
      }
      
  }, [locationData, data])
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    Mounted();

  }, []);

  function checkLanguage (type) {

    const lng = localStorage.getItem("language")
    let word;
    if (type == "isLoading") {
      if ( lng == "ru" ) {
        word = Language.ru.isLoading
      }
      else if ( lng == "en" ) {
        word = Language.en.isLoading
      }
      else if ( lng == "es" ) {
        word = Language.es.isLoading
      }
      else {
        word = Language.en.isLoading
      }
    }

    return word
  }
  return (

      <div style={styles.PlaceWrapper}>
        <Helmet>
          <title>{ getTitlte() }</title>
        </Helmet>
        {newData.length == 0
          ? (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: 100
            }}>
              <span style={{color: "#a6a6a6",fontSize: 12,padding: 10,}}>{checkLanguage("isLoading")}...</span>
            </div>
          )
          : (
            <div style={{paddingTop: 21}}>
              {newData.map((item, idx) => {
                return(
                  <div onClick={() => {
                    history.push(`/p/${item.id}`)
                  }} >
                    <PlaceItemView key={idx}
                                   item={item}  />
                  </div>
                )
              })}
              {/* {Object.keys(data).map((key, i) => {
          return (

                <div onClick={() => {
                  history.push(`/p/${data[key].id}`)
                }} >
                  <PlaceItemView key={i}
                                 item={data[key]}  />
                </div>
          );
        })} */}
            </div>
          )
        }

      </div>

  );
};

export default Place;
