import React, { useEffect, useState, useMemo } from "react"
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import {headers_token} from "../../../config";
import PlaceItemView from "../../PlaceItemView"
import {useHistory, useLocation} from "react-router-dom";
export default function CardDetailView ( props ) {
    let history = useHistory();

    const { selected, position: {cardPosition, setCardPosition},animation : {animation, setAnimation} , animationEnd: {animationEnd, setAnimationEnd}, present: {present, setPresent}} = props
    const [isLoadingPlaces, setIsLoadingPlaces] = useState(false)
    const [data, setData] = useState(null)
    const [handleUrlTransition, setHandleUrlTransition] = useState("SITE")
    function createMarkup() {
        return {
            __html: selected.content
        }
    }
    async function Mounted () {
        let query = "https://apimipt.4129.ru/api/place?"
        for (let i = 0; i < selected.navigatePlaces.length; i++) {
            query += `filter[id][]=${selected.navigatePlaces[i].id}`
            if ( i != selected.navigatePlaces.length - 1 ) {
                query += "&"
            }
        }
        console.log(query)
        try {
            const response = await axios.get(
                query,
                headers_token
            );
            const { paginator } = response.data.data;
            setData(paginator)

        } catch (error) {
            console.log(error);
        }
    }
    useMemo(() => {
        if ( data != null ) {
            setIsLoadingPlaces(true)
            console.log(data)
        }
        if (handleUrlTransition == "SITE") {

        }
        if (handleUrlTransition == "URL") {
            const url = window.location.href

            const args = url.substr(9)

        }
    },[data, handleUrlTransition])
    useEffect(() => {

        if (selected.navigatePlaces.length > 0 ) {
            Mounted()
        }

    }, [])
    function lengthData (data) {
        let array = []
        for(let item in data) {
            array.push(item)
        }
        return array.length
    }
    return(

        <div style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            opacity: animationEnd ? 1 : 0,
            zIndex: 99998
        }} >
            <img src={`https://apimipt.4129.ru/get-file.php?id=${selected.summaryImage.signatures[0].id}&dir=${selected.summaryImage.signatures[0].dir}&path=${selected.summaryImage.signatures[0].path}&style=thumbnail`}
                 style={{
                     width: "100%",
                     height: cardPosition.height,
                     objectFit: "cover",
                 }}
            />
            <h5 style={{
                position: "absolute",
                top: 30,
                left: 10,
                color: "#FFFFFF",
                zIndex: 99999,
                width: "80%",
                transition: "0.4s"
            }}>{selected.title}</h5>
            <h2 style={{
                position: "absolute",
                top: 22,
                right: 20,
                zIndex: 9999999,
                opacity: animationEnd ? 1 : 0,
                transition: "ease-in-out 0.4s",
                color: "#FFFFFF"
            }}
                onClick={() => {
                    history.push(`/history`)
                    setAnimationEnd(false)
                    setAnimation(false)

                    setTimeout(() => {
                        setPresent(false)
                        setCardPosition(null)
                    }, 490)
                }}
            ><AiFillCloseCircle />
            </h2>
            <div style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#FFFFFF"
            }}>


                <div style={{ padding: 10,
                    opacity: animationEnd ? 1 : 0,
                    transition: "ease-in-out 0.4s",
                }} >
                    <div dangerouslySetInnerHTML={createMarkup()} />

                    {isLoadingPlaces && data != null ? (
                        <div>
                            {lengthData(data) > 0 && (
                                <h4 style={{

                                    marginTop: 10
                                }}>По стопам истории</h4>
                            )}

                            {
                                Object.keys(data).map((key, index) => {
                                    return (
                                        <div onClick={() => {
                                            history.push(`/p/${data[key].id}`)
                                        }} >

                                            <PlaceItemView key={index} item={data[key]}  />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (<></>)}
                </div>


            </div>

        </div>
    )
}
