import React, {useRef, useEffect} from "react"

export default function CardView ( props ) {
    const container = useRef(null)
    const { positionCard, title, image } = props
    function OnPressCard () {
        let position = {
            width: container.current.getBoundingClientRect().width,
            height: container.current.getBoundingClientRect().height,
            x: container.current.getBoundingClientRect().x,
            y: container.current.getBoundingClientRect().y
        }

        positionCard(position)
    }
    useEffect(() => {
        console.log(image)
    }, [])
    return (
        <div style={CardStyleWrapper}>
            <img ref={container}
                 src={image}  width="90%" height="390" style={CardStyleImage} onClick={OnPressCard} />
            <h5 style={styleTitle}>{title}</h5>
        </div>
    )
}


const CardStyleWrapper = {
    marginBottom: 25,
    position: "relative",
    textAlign: "center"
}
const CardStyleImage = {
    borderRadius: 15,
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    objectFit: "cover"
}
const styleTitle = {
    position: "absolute",
    top: 30,
    left: 30,
    color: "#FFFFFF",
    textAlign: "left",
    width: "80%",
}
